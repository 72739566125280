import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DropdownNavbar from '../../Components/DropdownNavbar';
import Navbar from '../../Components/Navbar';
import Scene from './Scene';


const Portfolio = () => {


 const [toggle, setToggle] = React.useState(true);
 const handleToggle = () => {
  setToggle(!toggle);
 };



 return (
  <>
   <DropdownNavbar onclick={handleToggle} toggle={toggle} />
   <Navbar click={handleToggle} header={"My Portfolio"} />

   <div style={{background: "goldenrod", height: "95vh"}}>  {toggle && <Scene/>  }  </div>


  </>
 );
};

export default Portfolio;
