import * as THREE from 'three'
import {  useLayoutEffect, useState, } from 'react'
import { Canvas,} from '@react-three/fiber'
import { Center, AccumulativeShadows, RandomizedLight, OrbitControls, Environment, useGLTF, } from '@react-three/drei'
import { FlakesTexture } from 'three-stdlib'
import CloudComponent from './cloud'
import { Model } from './shape'



export default function Scene() {


  const [zoom, setZoom] = useState(false)



  console.log(zoom, "zoom")

    

  return (
    <Canvas shadows camera={{ position: [-5, 0, -15], fov: 55 }}>
        <CloudComponent />

        <group rotation={[0, Math.PI, 0]} position={[3, 1, 0]}>
          <Model />
        </group>



      <group position={[-6, -0.5, 0]}>
        <Center top onPointerOver={()=> setZoom(true)}
      onPointerOut={()=> setZoom(false)}>
        <Suzi rotation={[0.63, Math.PI, 0]} scale={2} />
        
        </Center>
        <Center top position={[-3, 0, 1]}>
          <mesh castShadow>
            <sphereGeometry args={[0.25, 64, 64]} />
            <meshStandardMaterial color="lightblue" />
          </mesh>

          

          <mesh scale={ 1}>
      
   
   
    </mesh>
        </Center>

        <Center top position={[-6, 0, -1]}>
          <mesh castShadow>
            <sphereGeometry args={[0.25, 64, 64]} />
            <meshStandardMaterial color="pink" />
          </mesh>

          

          <mesh scale={ 1}>
      
   
   
    </mesh>
        </Center>

        
        <Center top position={[2.5, 0, 1]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[-0.25, 64, 64]} />
            <meshStandardMaterial color="indianred"  />
          </mesh>
        </Center>

        <Center top position={[-2.5, 0, -3]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[-0.25, 64, 64]} />
            <meshStandardMaterial color="indianred"  />
          </mesh>
        </Center>

        <Center top position={[3.5, 0, -6]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[-0.25, 64, 64]} />
            <meshStandardMaterial color="silver"  />
          </mesh>
        </Center>
        <Center top position={[18.5, 3, 5]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[-0.25, 64, 64]} />
            <meshStandardMaterial color="red"  />
          </mesh>
        </Center>
        <Center top position={[17.5, 1, 5]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[-0.25, 64, 64]} />
            <meshStandardMaterial color="green"  />
          </mesh>
        </Center>
        <Center top position={[17.5, 5, 0]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[-0.25, 64, 64]} />
            <meshStandardMaterial color="pink"  />
          </mesh>
        </Center>
        <Center top position={[18.5, -2, 0]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[-0.25, 64, 64]} />
            <meshStandardMaterial color="#8FBC8F"  />
          </mesh>
        </Center>
        <Center top position={[16.5, 1, 0]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[-0.25, 64, 64]} />
            <meshStandardMaterial color="#191970"  />
          </mesh>
        </Center>
        <Center top position={[-5.5, 5, 0]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[-0.25, 64, 64]} />
            <meshStandardMaterial color="#C71585"  />
          </mesh>
        </Center>

        <Center top position={[-4.5, 4, 0]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[-0.25, 64, 64]} />
            <meshStandardMaterial color="#808000"  />
          </mesh>
        </Center>
        <Center top position={[18.5, -3, -2]}>
          <mesh castShadow rotation={[0, Math.PI / 4, 0]}>
          <sphereGeometry args={[-0.25, 64, 64]} />
            <meshStandardMaterial color="yellow"  />
          </mesh>
        </Center>
        <AccumulativeShadows temporal frames={100} color="orange" colorBlend={2} toneMapped={true} alphaTest={0.75} opacity={2} scale={12}>
          <RandomizedLight intensity={Math.PI} amount={8} radius={4} ambient={0.5} position={[-6, 10, -10]} bias={0.001} />
        </AccumulativeShadows>
      </group>
      {/* <OrbitControls minPolarAngle={0} maxPolarAngle={Math.PI / 2} /> */}
      <OrbitControls enablePan={false} enableZoom={false} minPolarAngle={Math.PI / 2.2} maxPolarAngle={Math.PI / 2.2} />
      <Environment preset="city" />
    </Canvas>
  )
}

export function Suzi(props) {
  const { scene, materials } = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/suzanne-high-poly/model.gltf')
  useLayoutEffect(() => {
    scene.traverse((obj) => obj.isMesh && (obj.receiveShadow = obj.castShadow = true))
    materials.default.color.set('orange')
    materials.default.roughness = 0
    materials.default.normalMap = new THREE.CanvasTexture(new FlakesTexture(), THREE.UVMapping, THREE.RepeatWrapping, THREE.RepeatWrapping)
    materials.default.normalMap.repeat.set(40, 40)
    materials.default.normalScale.set(0.1, 0.1)
  })
  return <primitive object={scene} {...props} />
}



