import * as THREE from "three"
import { createContext, useContext, useRef, useState } from "react"
import { useFrame } from "@react-three/fiber"
import { Clouds, Cloud, ContactShadows,} from "@react-three/drei"
import { CuboidCollider, BallCollider, Physics, RigidBody } from "@react-three/rapier"
import { random } from "maath"

const context = createContext()
export default function CloudComponent() {
  const shake = useRef()
  return (
    <>
      <ambientLight intensity={Math.PI / 2} />
      <context.Provider value={shake}>
      
        <Clouds limit={100} material={THREE.MeshLambertMaterial}>
          <Physics gravity={[0, 0, 0]}>
            <Pointer />
            <Puffycloud seed={10} position={[50, 0, 0]}/>
            <Puffycloud seed={20} position={[0, 50, 0]}   />
            <Puffycloud seed={30} position={[50, 0, 50]}  />
            <Puffycloud seed={70} position={[0, 0, -50]}   />
            <CuboidCollider position={[0, -15, 0]} args={[400, 10, 400]} />
          </Physics>
        </Clouds>
      </context.Provider>
      <mesh scale={200}>
        <sphereGeometry />
        <meshStandardMaterial color="goldenrod" roughness={0.7} side={THREE.BackSide} />
      </mesh>
      <ContactShadows opacity={0.25} color="goldenrod" position={[0, -10, 0]} scale={50} blur={2.5} far={40} />
 
    </>
  )
}

function Puffycloud({ seed, vec = new THREE.Vector3(), ...props }) {
  const api = useRef()
  const light = useRef()
  const rig = useContext(context)
  const [flash] = useState(() => new random.FlashGen({ count: 10, minDuration: 40, maxDuration: 200 }))
  const contact = (payload) => payload.other.rigidBodyObject.userData?.cloud && payload.totalForceMagnitude / 1000 > 100 && flash.burst()
  useFrame((state, delta) => {
    const impulse = flash.update(state.clock.elapsedTime, delta)
    light.current.intensity = impulse * 15000
    if (impulse === 1) rig?.current?.setIntensity(1)
    api.current?.applyImpulse(vec.copy(api.current.translation()).negate().multiplyScalar(10))
  })
  return (
    <RigidBody ref={api} userData={{ cloud: true }} onContactForce={contact} linearDamping={4} angularDamping={1} friction={0.1} {...props} colliders={false}>
      <BallCollider args={[4]} />
      <Cloud seed={seed} fade={30} speed={0.1} growth={4} segments={40} volume={6} opacity={0.6} bounds={[4, 3, 1]} />
      <Cloud seed={seed + 1} fade={30} position={[0, 1, 0]} speed={0.5} growth={4} volume={10} opacity={1} bounds={[6, 2, 1]} />
      <pointLight position={[0, 0, 0.5]} ref={light} color="blue" />
    </RigidBody>
  )
}

function Pointer({ vec = new THREE.Vector3(), dir = new THREE.Vector3() }) {
  const ref = useRef()
  useFrame(({ pointer, viewport, camera }) => {
    vec.set(pointer.x, pointer.y, 0.5).unproject(camera)
    dir.copy(vec).sub(camera.position).normalize()
    vec.add(dir.multiplyScalar(camera.position.length()))
    ref.current?.setNextKinematicTranslation(vec)
  })
  return (
    <RigidBody userData={{ cloud: true }} type="kinematicPosition" colliders={false} ref={ref}>
      <BallCollider args={[4]} />
    </RigidBody>
  )
}
